let appConfig = {
    base_url: 'https://api.kurrency.uat.e-makuta.com',
    api_prefix: 'https://api.kurrency.uat.e-makuta.com/api',
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    username_prefix: "SC230912094",
    tourPath: '/',
    locale: 'fr',
    enableMock: false,
}

appConfig = { ...appConfig, apiPrefix: `${appConfig.base_url}/api` }
export default appConfig
